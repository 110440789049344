<template>
    <div>
        <!-- Cancel unstarted campaign modal -->
        <generic-confirm-modal
            ref="modal-generate-invoice"
            title="Etes-vous sûre de vouloir regénérer la facture ?"
            cancel-title="Non"
            ok-title="Oui"
            @on-accept="generateInvoice()"
            @on-cancel="$refs['modal-generate-invoice'].close()"
        >
        </generic-confirm-modal>

        <b-card>
            <div></div>
            <b-input-group class="mb-1 w-25 d-flex ml-auto">
                <b-input-group-prepend>
                    <b-input-group-text>
                        <feather-icon icon="SearchIcon"></feather-icon>
                    </b-input-group-text>
                </b-input-group-prepend>
                <b-input v-model="filter"></b-input>
            </b-input-group>

            <b-table
                hover
                striped
                responsive
                ref="table"
                :filter="filter"
                :items="codeForTable"
                :fields="fields"
                :per-page="perPage"
                :current-page="currentPage"
                @row-clicked="(item) => toggleRow(item)"
                @filtered="onTableFiltered"
            >
                <template #row-details="row">
                    <h5 class="text-primary">Factures</h5>
                    <br /><br />
                    <b-container class="pad-0" style="max-width: 100%; margin: inherit">
                        <b-row>
                            <b-col><strong>Nom </strong></b-col>
                            <b-col><strong> Date </strong></b-col>
                            <b-col><strong> Total Amount </strong></b-col>
                            <b-col><strong> Deposit Amount TTC </strong></b-col>
                            <b-col><strong> Left to Pay </strong></b-col>
                            <b-col><strong> Facture </strong></b-col>
                        </b-row>
                        <b-row class="mb-1" v-for="(invoice, idx) in factures" :key="idx" v-if="invoice.details">
                            <b-col>{{ invoice.name }}</b-col>
                            <b-col v-if="invoice.details.invoiceDate"> {{ invoice.details.invoiceDate }}</b-col>
                            <b-col v-else> - </b-col>
                            <b-col v-if="invoice.details.campaignData">{{ invoice.details.campaignData.campaignTotalAmount / 100 }}€</b-col>
                            <b-col v-else-if="invoice.details.details.subTotal"> {{ invoice.details.details.subTotal / 100 }}€ </b-col>
                            <b-col v-else> - </b-col>
                            <b-col v-if="invoice.details.campaignData">{{ invoice.details.campaignData.depositAmountTTC / 100 }}€</b-col>
                            <b-col v-else> - </b-col>
                            <b-col v-if="invoice.details.campaignData">{{ invoice.details.campaignData.leftToPay / 100 }}€</b-col>
                            <b-col v-else> - </b-col>
                            <b-col class="d-flex justify-content-between">
                                <b-button size="sm" mb-6 variant="primary" @click="downloadInvoice(invoice.details.invoiceName)">Télecharger </b-button>
                                <b-button size="sm" mb-6 variant="primary" @click="openGenerateInvoiceModal(invoice.point_code, invoice.name)" class="ml-1">Regénérer Facture </b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </template>
            </b-table>
            <b-pagination class="mt-3" v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number></b-pagination>
        </b-card>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import FileSaver from 'file-saver';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

    export default {
        props: {
            posInfo: {
                type: Array,
                required: true,
            },
            brand: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                factures: ' ',
                resCampaign: '',
                zipcode: '',
                selectedPos: {},
                modalShow: false,
                invoiceCode: '',
                invoiceName: '',
                fields: [
                    { key: 'point_code', label: 'Code point de vente' },
                    { key: 'details.name', label: 'Nom' },
                    { key: 'customer_id', label: 'Id Client Stripe' },
                    { key: 'details.financial_group', label: 'Groupe Financier' },
                ],
                rows: 0,
                perPage: 10,
                currentPage: 1,
                filter: '',
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
            codeForTable() {
                let data = this.posInfo.map((banner) => {
                    return {
                        ...banner,
                        brandName: banner.point_code,
                        sourceName: banner.details.name,
                        _showDetails: false,
                    };
                });

                if (this.filter === '') this.setRows(data);
                return data;
            },
        },
        watch: {},
        methods: {
            setRows(list) {
                this.rows = list.length;
            },
            onTableFiltered(list) {
                this.setRows(list);
            },
            toggleRow(row) {
                if (row._showDetails === false) {
                    let payload = {
                        point_code: row.point_code,
                        brand: this.brand,
                    };

                    this.$root.toggleGlobalLoading(true);
                    this.$store
                        .dispatch('app/getInvoices', payload)
                        .then((res) => {
                            this.$set(this, 'factures', res.data);
                        })
                        .catch((e) => {
                            console.error(e);
                        })
                        .finally(() => {
                            this.$root.toggleGlobalLoading(false);
                        });
                }
                row._showDetails = !row._showDetails;
            },
            downloadInvoice(invoiceName) {
                this.$root.toggleGlobalLoading(true);
                let payload = {
                    invoice_name: invoiceName,
                    brand: this.brand,
                };
                this.$store
                    .dispatch('app/downloadInvoice', payload)
                    .then((res) => {
                        let file = res.data;
                        FileSaver.saveAs(file, `${invoiceName}.pdf`);
                        this.$root.toggleGlobalLoading(false);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Le rapport a été téléchargé',
                                icon: 'smile',
                                variant: 'success',
                            },
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$root.toggleGlobalLoading(false),
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Ressource introuvable',
                                    icon: 'x-circle',
                                    variant: 'danger',
                                },
                            });
                    });
            },
            openGenerateInvoiceModal(code, name) {
                this.invoiceCode = code;
                this.invoiceName = name;
                this.$refs['modal-generate-invoice'].open();
            },
            generateInvoice() {
                this.$root.toggleGlobalLoading(true);

                let payload = {
                    point_code: this.invoiceCode,
                    brand: this.brand,
                    name: this.invoiceName,
                };
                this.$store
                    .dispatch('app/generateInvoice', payload)
                    .then(() => {
                        this.$root.toggleGlobalLoading(false);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Le rapport a été regénéré',
                                icon: 'smile',
                                variant: 'success',
                            },
                        });
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$root.toggleGlobalLoading(false);
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Ressource introuvable',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    })
                    .finally(() => {
                        this.$refs['modal-generate-invoice'].close();
                    });
            },
        },
    };
</script>

<style></style>
